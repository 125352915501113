<div class="timer" class:no-display={$status === types.STATUS_WAITING}>
  <i class="far fa-clock" />
  {timer}
</div>

<style src="./Timer.scss">.timer {
  text-align: center;
  font-weight: 700;
  line-height: 1;
  font-size: 32px;
}

.disabled {
  color: #e2e8f0;
}

.no-display {
  display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1RpbWVyL1RpbWVyLnN2ZWx0ZSIsIjxubyBzb3VyY2U+Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VDQUEsbUJBQUE7RUFBQSxpQkFBQTtFQUFBLGVBQUE7RURFRSxlQUFlO0FBQUU7O0FBRW5CO0VDSkEsZUFBQTtBREt3Qjs7QUFFeEI7RUFDRSxhQUFhO0FBQUUiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvVGltZXIvVGltZXIuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiLnRpbWVyIHtcbiAgQGFwcGx5IHRleHQtY2VudGVyIGZvbnQtYm9sZCBsZWFkaW5nLW5vbmU7XG4gIGZvbnQtc2l6ZTogMzJweDsgfVxuXG4uZGlzYWJsZWQge1xuICBAYXBwbHkgdGV4dC1ncmF5LTMwMDsgfVxuXG4ubm8tZGlzcGxheSB7XG4gIGRpc3BsYXk6IG5vbmU7IH1cblxuLyojIHNvdXJjZU1hcHBpbmdVUkw9VGltZXIuc3ZlbHRlLmNzcy5tYXAgKi8iLG51bGxdfQ== */</style>

<script src="./Timer.js">import { parseTimer } from "../../helpers/timer";
import { status } from "store/game/index.js";

import * as types from "helpers/types.js";

export let duration = 0;

$: timer = $status === types.STATUS_WAITING ? "" : parseTimer(duration);
</script>
