<div class="progress">
  <div class="bar" style={width} />
</div>

<style src="./ProgressBar.scss">.progress .bar {
  width: 0;
  height: 4px;
  border-radius: 4px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1Byb2dyZXNzQmFyL1Byb2dyZXNzQmFyLnN2ZWx0ZSIsIjxubyBzb3VyY2U+Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VDQUEsU0FBQTtFREVFLFdBQVc7RUFDWCxrQkFBa0I7QUFBRSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9Qcm9ncmVzc0Jhci9Qcm9ncmVzc0Jhci5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyIucHJvZ3Jlc3MgLmJhciB7XG4gIEBhcHBseSB3LTA7XG4gIGhlaWdodDogNHB4O1xuICBib3JkZXItcmFkaXVzOiA0cHg7IH1cblxuLyojIHNvdXJjZU1hcHBpbmdVUkw9UHJvZ3Jlc3NCYXIuc3ZlbHRlLmNzcy5tYXAgKi8iLG51bGxdfQ== */</style>

<script src="./ProgressBar.js">export let total;
export let progress;
export let background;

$: width = `width:${(progress / total) * 100}%; background: ${background}`;
</script>
