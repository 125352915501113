<div class="circle" style={componentStyles}>
  <span>
    {#each playerNameParts as namePartial}
      {#if playerNameParts.length === 1}
        {player.name
          .trim()
          .substr(0, 3)
          .toUpperCase()}
      {:else}{namePartial.trim()[0].toUpperCase()}{/if}
    {/each}
  </span>
</div>

<style src="./InitialCircle.scss">.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0luaXRpYWxDaXJjbGUvSW5pdGlhbENpcmNsZS5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFhO0VBQ2IsbUJBQW1CO0VBQ25CLHVCQUF1QjtFQUN2QixnQkFBZ0I7RUFDaEIsWUFBWTtBQUFFIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL0luaXRpYWxDaXJjbGUvSW5pdGlhbENpcmNsZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyIuY2lyY2xlIHtcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGZvbnQtd2VpZ2h0OiA2MDA7XG4gIGNvbG9yOiB3aGl0ZTsgfVxuXG4vKiMgc291cmNlTWFwcGluZ1VSTD1Jbml0aWFsQ2lyY2xlLnN2ZWx0ZS5jc3MubWFwICovIl19 */</style>

<script src="./InitialCircle.js">export let player = {};
export let zoom = 1;

$: playerNameParts = player.name.trim().split(" ");
$: componentStyles = `background: ${player.color}; 
              height: ${48 * zoom}px;
              width: ${48 * zoom}px;
              font-size: ${16 * zoom}px; 
              border-radius: ${24 * zoom}px`;
</script>
