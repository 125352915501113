<div class="container">
  <div class="ranking-index w-1/12">{index}.</div>
  <div class="leaderboard-item w-11/12">

    <div class="w-2/12">
      <InitialCircle {player} zoom="0.8" />
    </div>
    <div class="w-6/12 player-name">{player.name}</div>
    <div class="w-4/12 player-score">{player.score}s</div>
  </div>

</div>

<style src="./LeaderboardItem.scss">.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
}

.leaderboard-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  height: 48px;
  width: 560px;
  background: white;
  margin-bottom: 2px;
  border-radius: 8px;
}

.player-name,
.player-score {
  font-size: 17px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  color: #0a0e1b;
}

.ranking-index {
  margin-right: 15px;
  font-size: 21px;
  color: #ffffff;
  text-align: right;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0xlYWRlcmJvYXJkSXRlbS9MZWFkZXJib2FyZEl0ZW0uc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBYTtFQUNiLG1CQUFtQjtFQUNuQix1QkFBdUI7RUFDdkIsWUFBWTtBQUFFOztBQUVoQjtFQUNFLGFBQWE7RUFDYixtQkFBbUI7RUFDbkIsdUJBQXVCO0VBQ3ZCLGFBQWE7RUFDYixZQUFZO0VBQ1osWUFBWTtFQUNaLGlCQUFpQjtFQUNqQixrQkFBa0I7RUFDbEIsa0JBQWtCO0FBQUU7O0FBRXRCOztFQUVFLGVBQWU7RUFDZixrQkFBa0I7RUFDbEIsb0JBQW9CO0VBQ3BCLG1CQUFtQjtFQUNuQixzQkFBc0I7RUFDdEIsY0FBYztBQUFFOztBQUVsQjtFQUNFLGtCQUFrQjtFQUNsQixlQUFlO0VBQ2YsY0FBYztFQUNkLGlCQUFpQjtBQUFFIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL0xlYWRlcmJvYXJkSXRlbS9MZWFkZXJib2FyZEl0ZW0uc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbnRhaW5lciB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICB3aWR0aDogNjAwcHg7IH1cblxuLmxlYWRlcmJvYXJkLWl0ZW0ge1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgcGFkZGluZzogMTVweDtcbiAgaGVpZ2h0OiA0OHB4O1xuICB3aWR0aDogNTYwcHg7XG4gIGJhY2tncm91bmQ6IHdoaXRlO1xuICBtYXJnaW4tYm90dG9tOiAycHg7XG4gIGJvcmRlci1yYWRpdXM6IDhweDsgfVxuXG4ucGxheWVyLW5hbWUsXG4ucGxheWVyLXNjb3JlIHtcbiAgZm9udC1zaXplOiAxN3B4O1xuICBmb250LXN0eWxlOiBub3JtYWw7XG4gIGZvbnQtc3RyZXRjaDogbm9ybWFsO1xuICBsaW5lLWhlaWdodDogbm9ybWFsO1xuICBsZXR0ZXItc3BhY2luZzogMC4wNHB4O1xuICBjb2xvcjogIzBhMGUxYjsgfVxuXG4ucmFua2luZy1pbmRleCB7XG4gIG1hcmdpbi1yaWdodDogMTVweDtcbiAgZm9udC1zaXplOiAyMXB4O1xuICBjb2xvcjogI2ZmZmZmZjtcbiAgdGV4dC1hbGlnbjogcmlnaHQ7IH1cblxuLyojIHNvdXJjZU1hcHBpbmdVUkw9TGVhZGVyYm9hcmRJdGVtLnN2ZWx0ZS5jc3MubWFwICovIl19 */</style>

<script src="./LeaderboardItem.js">import InitialCircle from "components/InitialCircle/InitialCircle.svelte";

export let player = {};
export let index = 0;
</script>
