<div class="brand">
  <img
    src="/logo.svg"
    alt="The Software House" />
</div>

<style src="./Brand.scss">.brand {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0JyYW5kL0JyYW5kLnN2ZWx0ZSIsIjxubyBzb3VyY2U+Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VDQUEsY0FBQTtFQUFBLG9CQUFBO0VBQUEsd0JBQUE7QURDMkMiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvQnJhbmQvQnJhbmQuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiLmJyYW5kIHtcbiAgQGFwcGx5IGZsZXggaXRlbXMtY2VudGVyIGp1c3RpZnktY2VudGVyOyB9XG5cbi8qIyBzb3VyY2VNYXBwaW5nVVJMPUJyYW5kLnN2ZWx0ZS5jc3MubWFwICovIixudWxsXX0= */</style>
